<template>
  <sb-card :loading="loading">
    <template slot="title">
      <v-card-title>
        <sb-title title="Task Details" />
        <v-spacer />
        <v-tooltip v-if="!loading"
                   left>
          <template #activator="{on}">
            <v-chip :color="getStatusColor(taskDetail.status.key)"
                    label
                    dark
                    tag="div"
                    v-on="on">
              {{ taskDetail.status.value }}
            </v-chip>
          </template>
          <span>Status</span>
        </v-tooltip>
      </v-card-title>
    </template>

    <sb-empty-message v-if="!taskDetail.id"
                      message="No Task Detail Available" />

    <v-container v-if="!loading && taskDetail.id">
      <v-row class="mb-5">
        <v-col cols="12"
               sm="6">
          <sb-input label="External Type"
                    :value="taskDetail.externalReferenceType" />
        </v-col>
        <v-col cols="12"
               sm="6">
          <sb-input label="Timestamp"
                    :value="taskDetail.createdTimestamp" />
        </v-col>
        <v-col cols="12"
               sm="6">
          <sb-input label="Status"
                    :value="taskDetail.status.value" />
        </v-col>
        <v-col cols="12"
               sm="6">
          <sb-input label="State"
                    :value="taskDetail.state.value" />
        </v-col>
        <v-col cols="12"
               sm="6">
          <sb-input label="External Reference"
                    :value="taskDetail.externalReferenceId" />
        </v-col>
        <v-col v-if="taskDetail.technicianDetails.companyName"
               cols="12"
               sm="6">
          <sb-input label="Technician Company"
                    :value="taskDetail.technicianDetails.companyName" />
        </v-col>
        <v-col v-if="taskDetail.technicianDetails.driverName"
               cols="12"
               sm="6">
          <sb-input label="Driver Detail"
                    :value="taskDetail.technicianDetails.driverName" />
        </v-col>
        <v-col v-if="taskDetail.technicianDetails.registrationNumber"
               cols="12"
               sm="6">
          <sb-input label="Registration Number (Recovery Vehicle)"
                    :value="taskDetail.technicianDetails.registrationNumber" />
        </v-col>
      </v-row>
    </v-container>
  </sb-card>
</template>

<script>
import { TASK_STATUSES } from '@/config/constants';

export default {
  props: {
    taskDetail: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getStatusColor(key) {
      let color;

      if (key === TASK_STATUSES.TASK_STATUS_CLOSED.value) {
        color = 'error';
      } else {
        color = 'success';
      }

      return color;
    },
  },
};
</script>
