<template>
  <sb-page-content title="Task Details"
                   class="back-button-margin">
    <v-container class="pa-0">
      <v-row>
        <v-col cols="12">
          <sb-task-detail :task-detail="serviceRequestTask"
                          :loading="$wait.is('serviceRequestTaskLoading')" />
        </v-col>

        <v-col cols="12"
               class="py-0">
          <div class="text-h2 my-5">
            Events
          </div>
        </v-col>

        <v-col cols="12">
          <sb-event-timeline :reference-id="taskId"
                             :event-type="type" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <sb-back-button :url="`/sla-management/service-requests/${serviceRequestNumber}`"
                          tooltip="Return to Service Request" />
        </v-col>
      </v-row>
    </v-container>
  </sb-page-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import SbTaskDetail from '@/components/task/SbTaskDetail';
import SbBackButton from '@/components/core/SbBackButton';
import SbEventTimeline from '@/widgets/event/SbEventTimeline';

import { SEVERITIES, TASK_STATUSES, EVENT_TYPES } from '../../../config/constants';

export default {
  components: {
    SbTaskDetail,
    SbBackButton,
    SbEventTimeline,
  },

  props: {
    serviceRequestNumber: {
      type: String,
      default: '',
    },
    taskId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      SEVERITIES,
      collectionDatePicker: false,
      numberOfElementsArray: [4, 8, 12],
      search: '',
      filter: {},
      page: 1,
      numberOfElements: 4,
      type: EVENT_TYPES.TASK,
    };
  },

  computed: {
    ...mapGetters({
      serviceRequestTask: 'serviceRequests/serviceRequestTask',
    }),

    numberOfPages() {
      return Math.ceil(this.serviceRequest.tasks.length / this.numberOfElements);
    },

    filteredKeys() {
      return this.keys.filter((key) => key !== 'Name');
    },

    isTaskClosed() {
      return this.serviceRequestTask.status.key === TASK_STATUSES.TASK_STATUS_CLOSED.value;
    },
  },

  created() {
    const serviceRequestPayload = {
      serviceRequestNumber: this.serviceRequestNumber,
      taskId: this.taskId,
    };

    this.getServiceRequestTask(serviceRequestPayload);
  },

  methods: {
    ...mapActions('serviceRequests', ['getServiceRequestTask']),

    ...mapWaitingActions('serviceRequests', {
      getServiceRequestTask: 'serviceRequestTaskLoading',
    }),

    nextPage() {
      if (this.page + 1 <= this.numberOfPages) {
        this.page += 1;
      }
    },

    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
      }
    },

    updateItemsPerPage(number) {
      this.numberOfElements = number;
    },
  },
};
</script>
