<template>
  <v-tooltip v-if="!$wait.any"
             bottom>
    <template #activator="{on}">
      <v-btn class="mt-5"
             color="secondary"
             large
             absolute
             bottom
             depressed
             v-on="on"
             @click="goBack">
        <v-icon left>
          mdi-keyboard-backspace
        </v-icon>
        {{ text }}
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  components: {},

  props: {
    text: {
      type: String,
      default: 'Back',
    },
    tooltip: {
      type: String,
      default: 'Back',
    },
    url: {
      type: String,
      default: undefined,
    },
  },

  methods: {
    goBack() {
      if (this.url !== undefined) {
        this.$router.push(this.url);
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
