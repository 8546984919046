import { getCurrentTimestamp } from '@/utils';

import User from '@/models/User';

export default class Comment {
  constructor(existingObject) {
    const object = existingObject || {};

    this.user = new User(object.user);
    this.message = object.message || undefined;
    this.timestamp = object.timestamp || getCurrentTimestamp();
  }
}
